import { Promise } from '../polyfills';
import { globalState } from './patch-utils';
import { SCHEDULE, INVOKE, FETCH } from '../constants';
import { scheduleMicroTask } from '../utils';
export function patchFetch(callback) {
  if (!window.fetch || !window.Request) {
    return;
  }

  function scheduleTask(task) {
    task.state = SCHEDULE;
    callback(SCHEDULE, task);
  }

  function invokeTask(task) {
    task.state = INVOKE;
    callback(INVOKE, task);
  }

  var nativeFetch = window.fetch;

  window.fetch = function (input, init) {
    var fetchSelf = this;
    var args = arguments;
    var request, url;

    if (typeof input === 'string') {
      request = new Request(input, init);
      url = input;
    } else if (input) {
      request = input;
      url = request.url;
    } else {
      return nativeFetch.apply(fetchSelf, args);
    }

    var task = {
      source: FETCH,
      state: '',
      type: 'macroTask',
      data: {
        target: request,
        method: request.method,
        url: url,
        aborted: false
      }
    };
    return new Promise(function (resolve, reject) {
      globalState.fetchInProgress = true;
      scheduleTask(task);
      var promise;

      try {
        promise = nativeFetch.apply(fetchSelf, [request]);
      } catch (error) {
        reject(error);
        task.data.error = error;
        invokeTask(task);
        globalState.fetchInProgress = false;
        return;
      }

      promise.then(function (response) {
        resolve(response);
        scheduleMicroTask(function () {
          task.data.response = response;
          invokeTask(task);
        });
      }, function (error) {
        reject(error);
        scheduleMicroTask(function () {
          task.data.error = error;
          invokeTask(task);
        });
      });
      globalState.fetchInProgress = false;
    });
  };
}