import PerformanceMonitoring from './performance-monitoring';
import TransactionService from './transaction-service';
import { APM_SERVER, CONFIG_SERVICE, LOGGING_SERVICE } from '../common/constants';
import { serviceCreators } from '../common/service-factory';

function registerServices() {
  serviceCreators['TransactionService'] = function (serviceFactory) {
    var _serviceFactory$getSe = serviceFactory.getService([LOGGING_SERVICE, CONFIG_SERVICE]),
        loggingService = _serviceFactory$getSe[0],
        configService = _serviceFactory$getSe[1];

    return new TransactionService(loggingService, configService);
  };

  serviceCreators['PerformanceMonitoring'] = function (serviceFactory) {
    var _serviceFactory$getSe2 = serviceFactory.getService([APM_SERVER, CONFIG_SERVICE, LOGGING_SERVICE, 'TransactionService']),
        apmServer = _serviceFactory$getSe2[0],
        configService = _serviceFactory$getSe2[1],
        loggingService = _serviceFactory$getSe2[2],
        transactionService = _serviceFactory$getSe2[3];

    return new PerformanceMonitoring(apmServer, configService, loggingService, transactionService);
  };
}

export { registerServices };