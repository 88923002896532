import { isPlatformSupported, isBrowser, now } from './common/utils';
import { patchAll } from './common/patching';
import { state } from './state';
var enabled = false;
export function bootstrap() {
  if (isPlatformSupported()) {
    patchAll();
    bootstrapPerf();
    state.bootstrapTime = now();
    enabled = true;
  } else if (isBrowser) {
    console.log('[Elastic APM] platform is not supported!');
  }

  return enabled;
}
export function bootstrapPerf() {
  if (document.visibilityState === 'hidden') {
    state.lastHiddenStart = 0;
  }

  window.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'hidden') {
      state.lastHiddenStart = performance.now();
    }
  }, {
    capture: true
  });
}